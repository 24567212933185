<script lang="ts" setup>
import { usePerformanceCodes } from '~/pages/schedule/composables/usePerformanceCodes';
import { GlobalModalKey } from '~/resources/modal/modal.interface';

// #region Globals
const route = useRoute();
const dayjs = useDayjs();
// #endregion

// #region Props
const props = defineProps({
   planningRequest: { type: Object as PropType<PlanningRequest>, required: true },
   date: { type: String, default: null },
});
const planningRequest = toRef(props, 'planningRequest');
// #endregion

// #region PlanningRequest
const { actions, color } = usePlanningRequest(planningRequest, props.date);

const confirmationModalRoute = computed(() => ({
   ...route,
   hash: `${GlobalModalKey.PlanningRequest}?id=${props.planningRequest.sync_id}`,
}));

const slot = computed(() =>
   props.planningRequest?.slots?.find((slot) => slot?.date === dayjs(props.date ?? undefined).format('YYYY-MM-DD')),
);
const { performanceCodes } = usePerformanceCodes(slot);
// #endregion
</script>

<template>
   <LayoutCard :class="'approved-card'" :accent-color="color" @click="$router.push(confirmationModalRoute)">
      <div class="approved-card__content">
         <div class="approved-card__heading">
            <PlanningRequestHeader class="approved-card__header" :planning-request="planningRequest" />

            <div class="approved-card__badges">
               <!-- TAGS -->
               <StatusHoverCard v-for="tag in slot?.tags ?? []" :key="tag.id" :background-color="tag.tag_type?.color ?? 'var(--nxt-dark)'">
                  <small>{{ tag.abbreviation }}</small>

                  <template #content>
                     <span> {{ tag.name }}</span>
                  </template>
               </StatusHoverCard>

               <!-- PERFORMANCE CODES -->
               <StatusHoverCard
                  v-for="(performanceCode, index) in performanceCodes"
                  :key="index"
                  :background-color="performanceCode?.color"
               >
                  <i :class="performanceCode.icon_key"></i>

                  <template #content>
                     {{ performanceCode.name }}
                  </template>
               </StatusHoverCard>
            </div>
         </div>

         <div class="approved-card__slot">
            <PlanningRequestSlot v-if="slot" :planning-request-slot="slot" />
         </div>

         <div class="approved-card__actions">
            <ButtonLink
               v-for="action in actions"
               :key="action.key"
               class="approved-card__actions__action"
               :color="'var(--nxt-dark)'"
               @click.stop="action.action"
            >
               <i :class="['fa-solid', `fa-${action.icon}`]"></i>
            </ButtonLink>
         </div>
      </div>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.approved-card {
   height: fit-content;
   width: 100%;

   &:deep(.card__content) {
      height: 100%;
   }
}

.approved-card__content {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
   height: 100%;
}

.approved-card__heading {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   gap: var(--nxt-gutter-small);
   margin-bottom: var(--nxt-gutter-small);
}

.approved-card__badges {
   display: flex;
   gap: var(--nxt-gutter-small);
}

.approved-card__status {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
   max-width: toRem(120);
}

.approved-card__slot {
   height: 100%;
}

.approved-card__actions {
   display: flex;
   gap: var(--nxt-gutter-small);
   align-self: flex-end;
}

.approved-card__actions__action {
   display: flex;
   gap: var(--nxt-gutter-small);
   align-items: center;
   justify-content: center;
   height: toRem(30);
   width: toRem(30);
}
</style>
