import type { LngLatLike } from 'maplibre-gl';
import { useAddToCalendar } from '~/pages/schedule/composables/useAddToCalendar';

export function usePlanningRequest(planningRequest: Ref<PlanningRequest>, date?: string) {
   const dayjs = useDayjs();
   const router = useRouter();
   const { t } = useI18n();
   const { openMaps, openPhone } = useLinks();
   const { addToCalendar } = useAddToCalendar(planningRequest);
   const { color } = useColorCode(planningRequest, date);

   const isPassedDeadline = computed(() => {
      if (!planningRequest.value) return false;
      return dayjs().isAfter(dayjs(planningRequest.value.must_be_handled_at), 'day');
   });

   const maps = computed<null | { coords?: LngLatLike; address?: string }>(() => {
      if (!planningRequest.value.squad?.street) return null;

      const { longitude, latitude } = planningRequest.value.squad;

      return {
         coords: longitude && latitude ? { lat: latitude, lng: longitude } : undefined,
         address: `${planningRequest.value.squad.street} ${planningRequest.value.squad.house_number}, ${planningRequest.value.squad.city?.name}`,
      };
   });

   const showTimeRegistrationAction = computed(() => {
      if (planningRequest.value?.slots?.length <= 0) return false;

      // If the first does not start within the next 4 hours, the user can't register time
      const firstSlot = planningRequest.value?.slots?.[0];
      const firstSlotDate = dayjs(firstSlot.started_at);
      const firstSlotStartDateIsInFuture = firstSlotDate.isAfter(dayjs().add(4, 'hour'));
      if (firstSlotStartDateIsInFuture) return false;

      // If the last slot is in the future, the user can register time
      const lastSlot = planningRequest.value?.slots[planningRequest.value?.slots.length - 1];
      const lastSlotDate = dayjs(lastSlot.ended_at);
      const lastSlotEndDateIsInFuture = lastSlotDate.isAfter(dayjs());
      if (lastSlotEndDateIsInFuture) return true;

      // If the last slot has not ended, the user can register time
      const lastSlotHasNotEndendPerformedHours = lastSlot?.performed_hours?.find((hour) => hour.end_time === null);
      if (lastSlotHasNotEndendPerformedHours) return true;

      return false;
   });

   const actions = computed(() =>
      [
         {
            key: 'phone',
            icon: 'phone',
            label: t('actions.call'),
            action: () => openPhone(planningRequest.value.team_phone_number!),
            enabled: !!planningRequest.value?.team_phone_number,
         },
         {
            key: 'calendar',
            icon: 'calendar-plus',
            label: t('schedules.add-to-calendar'),
            action: () => addToCalendar(),
            enabled: !isPassedDeadline.value,
         },
         {
            key: 'maps',
            icon: 'location-dot',
            label: t('actions.navigate'),
            action: () => openMaps(maps.value!),
            enabled: !!maps.value,
         },
         {
            key: 'time-registration',
            icon: 'qrcode',
            label: t('schedules.register-time'),
            action: () => router.push('/time-registration'),
            enabled: showTimeRegistrationAction.value,
         },
      ].filter((a) => a.enabled),
   );

   return {
      color,
      actions,
   };
}
